import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const CarOutlineIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M4.132 6.21h1.735v.572H4.132V6.21ZM2.99 5.803h.57v.571h-.57v-.57ZM6.989 5.803h-.571v.571h.571v-.57Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.1 2.017h.007l1.012 2.447h.013v3.25H7.56v.572H6.418v-.572H3.56v.572H2.417v-.572h-.57v-3.25h.01L2.872 2.02h.01V2H7.1v.017Zm-.382.554H3.26l-.783 1.893h5.024l-.783-1.893Zm-4.3 4.572H7.56V5.035H2.417v2.108Z"
            />
            <path d="M1.571 4.285H1v1.143h.571V4.285ZM8.428 4.285h.57v1.143h-.57V4.285Z" />
        </SvgIcon>
    );
};
