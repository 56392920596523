import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const WalkIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M6.025 1.211a.725.725 0 1 1-1.026 1.026.725.725 0 0 1 1.026-1.026ZM6.074 5.028l-.247-.543-.241-.534-.265 1.564.783.771V9h-.76V6.605l-.707-.695-.495 2.892-.21-.036h-.003l-.536-.09.884-5.16-.734.331v1.35H3v-1.7l1.384-.627.004-.002.478-.216.044.004.065.005.15.014.124.01.375.033.12.267.004.009.676 1.492h1.31v.543h-1.66Z" />
        </SvgIcon>
    );
};
