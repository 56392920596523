import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const LockOutlineIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M5.454 5.798a.561.561 0 0 1-.207.763l.265.832h-1.1l.266-.835a.561.561 0 1 1 .776-.76Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.833 2.867v.8h1.6V9H1.5V3.667h1.6v-.8a1.867 1.867 0 1 1 3.733 0ZM5.477 1.635a1.333 1.333 0 0 0-1.844 1.232v.8H6.3v-.8a1.333 1.333 0 0 0-.823-1.232ZM2.033 4.2v4.267H7.9V4.2H2.033Z"
            />
        </SvgIcon>
    );
};
