import {IPropertyDetail} from "../interfaces/IPropertyDetail";

export type IPropertyDiscountPriceFields = Pick<IPropertyDetail, "discount" | "price" | "lowest_price_within_30_days">;

/**
 * This helper is meant to keep the same condition/logic for showing discount prices in all places.
 * Remember to update any type assertion that are relevant to usage of this function, in case logic changes.
 * Remember, that "discount price" should ignore configuration.show_price, because we're obliged by the law, to show
 * price, if a discount is a price discount (and not free garage "discount"/"promotion" or others).
 *
 * @param property - Any property that has required fields regarding discount price
 */

export function isPropertyWithDiscountPrice(property: IPropertyDiscountPriceFields) {
    return Boolean(
        property.discount && property.price && property.price > 0 && property.lowest_price_within_30_days && property.lowest_price_within_30_days > 0
    );
}
