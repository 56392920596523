import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const PoiBusIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M3.13281 6.5332H4.19981V7.0662H3.13281V6.5332Z" />
            <path d="M6.8668 6.5332H5.7998V7.0662H6.8668V6.5332Z" />
            <path d="M2.06738 8.4H2.60038V8.933H3.66738V8.4H6.33338V8.933H7.40038V8.4H7.93339V2H2.06738V8.4ZM7.40038 5.2H5.26738V3.6H7.40038V5.2ZM4.73338 5.2H2.60038V3.6H4.73338V5.2ZM2.60038 7.867V5.733H7.40038V7.867H2.60038ZM7.40038 2.533V3.067H2.60038V2.533H7.40038Z" />
            <path d="M1.533 4.66699H1V5.73399H1.533V4.66699Z" />
            <path d="M8.4668 4.66699H8.9998V5.73399H8.4668V4.66699Z" />
        </SvgIcon>
    );
};
