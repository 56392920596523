import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const PercentIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M7.602 1.988 1.955 7.635l.377.377 5.647-5.647-.377-.377ZM3.1 4.2a1.6 1.6 0 1 0 0-3.2 1.6 1.6 0 0 0 0 3.2Zm0-2.675a1.075 1.075 0 1 1 0 2.15 1.075 1.075 0 0 1 0-2.15ZM6.833 5.8a1.6 1.6 0 1 0 0 3.2 1.6 1.6 0 0 0 0-3.2Zm0 2.675a1.075 1.075 0 1 1 .002-2.15 1.075 1.075 0 0 1-.002 2.15Z" />
        </SvgIcon>
    );
};
