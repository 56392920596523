import * as lscache from "lscache";

import {SENT_PROPERTY_APPLICATIONS} from "../../app/constants/storage_keys";

export const getStorageSentPropertyApplications = (): number[] => {
    return lscache.get(SENT_PROPERTY_APPLICATIONS) || [];
};

export const addPropertiesToStorageSentApplication = (propertyIds: number[]): void => {
    const sentPropertyApplications = getStorageSentPropertyApplications();

    const notDuplicates = propertyIds.filter((newPropertyId) => {
        const propertyInSent = Boolean(sentPropertyApplications.find((sentPropertyId) => sentPropertyId === newPropertyId));
        return !propertyInSent;
    });

    lscache.set(SENT_PROPERTY_APPLICATIONS, [...sentPropertyApplications, ...notDuplicates]);
};
