import React, {FC} from "react";
import {css, Theme} from "@emotion/react";
import {rgba} from "polished";

import {pointer} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons";
import {ICoreModalProps, ModalCore} from "@pg-design/modal";

const closeButton = (closeModal: (e: React.MouseEvent) => void) => <CloseIcon css={closeButtonStyle} size="2.2" onClick={closeModal} />;

export const RPModal: FC<ICoreModalProps> = (props) => {
    return (
        <ModalCore className={props.className} {...props} overlayStyle={overlayStyle} shouldCloseOnOverlayClick closeButton={props.closeButton || closeButton}>
            {props.children}
        </ModalCore>
    );
};

const overlayStyle = (theme: Theme) => css`
    z-index: 9000;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: ${theme.breakpoints.md}) {
        background-color: ${rgba(theme.colors.secondary, 0.75)};
    }
`;

const closeButtonStyle = css`
    ${pointer};
    position: absolute;
    top: 2rem;
    right: 2rem;
`;

// TODO: this atom MAY require a refactor, as it was developed for rp_old offer gallery in the first place and then reused in different places.
export const darkModalStyle = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.secondary};

    .modal-close-icon {
        fill: white;
    }
`;
